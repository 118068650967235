import XhrRequestHandler from '../../lib/XhrRequestHandler';
import DocumentsSystem, {
  IDocumentType,
  IProjectFolderSystem,
  IStoredProjectActivityDocument,
  IUploadedDocument,
} from '../documents/DocumentsSystem';
import Project from './Project';
import { ProjectRelatedDocument } from '../documents/ProjectRelatedDocument';
import ProjectFolder from './ProjectFolder';
import { ProjectActivityFolder } from './ProjectActivityFolder';
import { ProjectSettlementsFolder } from './ProjectSettlementsFolder';
import { ProjectCommonFolder } from './ProjectCommonFolder';
import LazyInitialization from '../../lib/LazyInitialization';
import SystemDocumentType from '../documents/SystemDocumentType';
import CollaboratorSystem, { ICollaborator } from '../collaborators/CollaboratorSystem';
import { Collaborator } from '../collaborators/Collaborator';
import SystemUploadedDocument from '../documents/SystemUploadedDocument';
import { SystemNotUploadedDocument } from '../documents/SystemNotUploadedDocument';
import { ProjectDocument } from '../documents/ProjectDocument';
import { ActivityDocument } from '../documents/ActivityDocument';
import { SettlementDocument } from '../documents/SettlementDocument';
import { ProjectExternalIntegrationFolder } from './ProjectExternalIntegrationFolder';
import { ProjectTrashFolder } from './ProjectTrashFolder';
import ProjectTeam, { IProjectTeam } from './ProjectTeam';
import { ProjectCompanyJson } from './ProjectCompany';
import CompanySystem from '../company/CompanySystem';
import { Company, CompanyJson } from '../company/Company';
import CollaboratorTeamSystem from '../collaborators/collaborator_team/CollaboratorTeamSystem';

import { SystemDateParser } from '../SystemDateParser';
import { DateTime } from 'luxon';
import DocumentUploadControl, {
  DocumentUploadControlSaved,
  IDocumentControl,
  IDocumentControlForList,
  IDocumentControlForListManager,
  IDocumentControlSaved,
} from '../documents/DocumentUploadControl';
import DocumentUploadControlPending from '../documents/DocumentUploadControlPending';
import DocumentUploadControlPendingSaved from '../documents/DocumentUploadControlSaved';
import DocumentUploadControlConfirmed from '../documents/DocumentUploadControlConfirmed';
import ProjectDescriptor, { IProjectDescriptor } from './ProjectDescriptor';
import OrganizationChartSystem from '../organization_chart/OrganizationChartSystem';
import { OrganizationChartNode } from '../organization_chart/OrganizationChartNode';
import { ActivityJson } from '../activity/ActivitySystem';

export interface ProjectJson {
  pro_id: string;
  pro_codigo: string;
  pro_nombre: string;
  pro_descripcion: string;
  pro_inicio: string;
  pro_fin: string;
  pro_estado: string;
  pro_activo: boolean;
  pro_us_pueden_editar_distrib: boolean;
  pro_us_pueden_visualizar_distrib: boolean;
  pro_facturable: boolean;
  pro_ind_horas: number;
  pro_owner_colid: string;
  pro_eqid: string;
  pro_permite_exceder_horas: boolean;
  pro_tipo_liquidacion: string;
  pro_total_horas: number;
  pro_deadline: string;
  pro_norid: string;
  pro_doc_confidenciales: boolean;
  pro_sugiere_tag: boolean;
  equipo: IProjectTeam;
  empresaXProyectoList: ProjectCompanyJson[];
  controlCarga?: IDocumentControl[] | IDocumentControlSaved[];
}

export interface IProjectForDefenseFile {
  pro_id: string;
  pro_codigo: string;
  pro_nombre: string;
  pro_descripcion: string;
  pro_inicio: string;
  pro_fin: string;
  pro_deadline: null;
  pro_total_horas: string;
  pro_ind_horas: null;
  pro_estado: string;
  pro_activo: string;
  pro_carid: null;
  pro_monedaid: null;
  pro_carga_colid: string;
  pro_owner_colid: string;
  pro_fecha_carga: string;
  pro_fecha_ult_act: null;
  pro_facturable: string;
  pro_norid: string;
  pro_permite_exceder_horas: string;
  pro_us_pueden_visualizar_distrib: string;
  pro_us_pueden_editar_distrib: string;
  pro_requiere_documentos: string;
  responsable_nombre: string;
  responsable_iniciales: string;
  responsable_imagen_url: null;
  empresas: string;
  colaboradores_cant: string;
  pro_tipo_liquidacion: string;
  periodo: string;
}

export interface IFormContextProjectCommon {
  common: IFormContextProject;
}
interface IFormContextProject {
  empresas: CompanyJson[];
  colaboradores: ICollaborator[];
  documentos_tipo: IDocumentType[];
}

export interface FormContextProject {
  companyList: Company[];
  organizationChartNodes: OrganizationChartNode[];
  collaboratorList: Collaborator[];
  documentTypeList: SystemDocumentType[];
}

enum ProjectFolderType {
  folder = 'carpeta',
  activities = 'actividades',
  settlements = 'liquidaciones',
  externalIntegration = 'integracion-externa',
  trash = 'papelera',
}

interface IBaseProjectFolder {
  pca_nombre: string;
  pca_padre: null | string;
  pca_proid: string;
  pca_tipo: ProjectFolderType;
}

export interface IEditDistributionInPeriod {
  distributionList: { empresaId: string; porcentaje: number }[];
  desde: string;
  hasta: string;
  proyectoId: string;
}

export interface IStoredProjectFolder extends IBaseProjectFolder {
  pca_id: string;
  pca_creada_por: string;
  pca_fecha_creacion: string;
  hasChildren?: boolean;
}

export interface IStoredProjectSettlementDocument {
  dxli_id: string;
  dxli_liqiid: string;
  dxli_docid: string;
  dxli_fecha_carga: string;
  dxli_activo: boolean;
  dxli_pcaid: string;
  documento: null | IUploadedDocument;
}

export type StoredDocumentRelatedToProject =
  | IUploadedDocument
  | IStoredProjectSettlementDocument
  | IStoredProjectActivityDocument;

export interface IQuantityByDate {
  fecha: string;
  qty: number;
}

export interface IQuantityAndAttachedByDate {
  fecha: string;
  attachs: number;
  qty: number;
}

export interface ICompanyHsByDate {
  date: string;
  hours: number;
  companyId: string;
  companyName: string;
}

interface IActivitiesSummary {
  qty: number;
  hours: number;
}

interface IDocumentsRequiredSummary {
  total: number;
  uploaded: number;
  expired: number;
}

export interface IProjectCollaboratorSummary {
  colaborador: ICollaborator;
  hours: number;
  activities: number;
  documents: number;
}

export interface IProjectCompanySummary {
  empresa: CompanyJson;
  hours: number;
}

interface IProjectSummaryData {
  progress: number;
  activities: IActivitiesSummary;
  activitiesPeriod: IActivitiesSummary;
  documents: {
    uploaded: number;
    uploadedPeriod: number;
    required: IDocumentsRequiredSummary;
    requiredPeriod: IDocumentsRequiredSummary;
  };
  collaborators: {
    active: number;
    activePeriod: number;
    total: number;
  };
  activitiesByDay?: IQuantityByDate[];
  documentsByDay?: IQuantityByDate[];
  emailsMMI: {
    qty: number;
    attachs: number;
  };
  emailsMMIByDay?: IQuantityAndAttachedByDate[];
}

export interface IQuantityByWeek {
  start: string;
  end: string;
  qty: number;
}

export interface IProjectSummary extends IProjectSummaryData {
  activitiesByWeek: IQuantityByWeek[];
  documentsByWeek: IQuantityByWeek[];
  emailsMMIByWeek: IQuantityByWeek[];
}

export interface IAssignDocumentData {
  documentId: string;
}

export class ProjectsSystem implements IProjectFolderSystem {
  private static readonly entityDVMBase = `/proyectoDVM`;
  static readonly formContextUrl = `${this.entityDVMBase}/getFormContext`;

  private readonly entityBase = '/proyectos';
  private readonly entityDVMBase = ProjectsSystem.entityDVMBase;
  private readonly foldersEntityBase = '/proyecto-carpetas';
  private readonly EquipoDVMBase = '/equipos';

  constructor(
    private requestHandler: XhrRequestHandler,
    private dateParser: SystemDateParser,
    private documentsSystem: DocumentsSystem,
    private collaboratorsSystem: CollaboratorSystem,
    private companySystem: CompanySystem,
    private collaboratorTeamSystem: CollaboratorTeamSystem,
    private organizationChartSystem: OrganizationChartSystem
  ) {}

  getDateParser(): SystemDateParser {
    return this.dateParser;
  }

  async getById(id: string): Promise<Project> {
    const data = await this.requestHandler.get<ProjectJson>(`${this.entityBase}/${id}`);

    return Project.fromJson(this, data);
  }

  async list(): Promise<ProjectDescriptor[]> {
    const data = await this.requestHandler.get<ProjectJson[]>(
      `${this.entityBase}/usuario-representado?plain=1&pro_activo=1`
    );

    return data
      .filter((project) => project.pro_activo)
      .map((project) => ProjectDescriptor.fromJson(project, this.getDateParser()));
  }

  async listOfCollaborator(col: Collaborator): Promise<Project[]> {
    const data = await this.requestHandler.get<ProjectJson[]>(
      `${this.entityBase}/of-collaborator/${col.getId()}`
    );

    return Promise.all(
      data.filter((project) => project.pro_activo).map((project) => Project.fromJson(this, project))
    );
  }

  async listOfCollaboratorProjects(col:string): Promise<Project[]> {
    const data = await this.requestHandler.get<ProjectJson[]>(
      `${this.entityBase}/of-collaborator/${col}`
    );
   
    return Promise.all(
      data.filter((project) => project.pro_activo).map((project) => Project.fromJson(this, project))
    );
  }

  async getFolderById(id: string, project: Project): Promise<ProjectFolder> {
    const data = await this.requestHandler.get<IStoredProjectFolder>(`${this.foldersEntityBase}/${id}`);

    return this.mapStoredProjectFolder(data, project);
  }

  async getFolderOfDocument(doc: SystemUploadedDocument, project: Project): Promise<ProjectFolder> {
    return this.getFolderById(doc.getFolderId(), project);
  }

  async getDocumentById(id: string): Promise<SystemUploadedDocument> {
    return this.documentsSystem.getById(id);
  }

  async rootFolderOf(project: Project) {
    const data = await this.requestHandler.get<IStoredProjectFolder>(
      `${this.entityBase}/${project.getId()}/root-folder`
    );

    return ProjectCommonFolder.fromIProjectFolder(this, project, data);
  }

  async documentsOf(folder: ProjectFolder): Promise<ProjectRelatedDocument[]> {
    const data = await this.requestHandler.get<StoredDocumentRelatedToProject[]>(
      `${this.foldersEntityBase}/documents-of/${folder.getId()}`
    );

    return Promise.all(data.map((doc) => this.buildProjectRelatedDocument(doc, folder.getProject())));
  }

  async projectRelatedDocument(id: string, projectId: string): Promise<ProjectRelatedDocument> {
    const [data, project] = await Promise.all([
      this.documentsSystem.relatedDocument(id),
      this.getById(projectId),
    ]);

    return this.buildProjectRelatedDocument(data, project);
  }

  async foldersChildrenOf(folder: ProjectFolder): Promise<ProjectFolder[]> {
    const data = await this.requestHandler.get<IStoredProjectFolder[]>(
      `${this.foldersEntityBase}/children-of/${folder.getId()}`
    );

    return this.mapProjectFolders(data, folder.getProject());
  }

  async moveDocumentsInto(folder: ProjectFolder, documents: ProjectRelatedDocument[]) {
    for (const document of documents) {
      const url = `${this.foldersEntityBase}/${document.getId()}/move-into/${folder.getId()}`;
      await this.requestHandler.post(url);
    }
  }

  async moveToTrash(folder: ProjectFolder): Promise<void> {
    return this.requestHandler.post(`${this.foldersEntityBase}/move-to-trash/${folder.getId()}`);
  }

  restoreFromTrash(folder: ProjectFolder): Promise<void> {
    return this.requestHandler.post(`${this.foldersEntityBase}/restore-from-trash/${folder.getId()}`);
  }

  async originalFolderOfDocument(document: ProjectRelatedDocument, project: Project): Promise<ProjectFolder> {
    const data = await this.requestHandler.get<IStoredProjectFolder>(
      `${this.entityBase}/original-folder-of/${document.getId()}`
    );

    return ProjectCommonFolder.fromIProjectFolder(this, project, data);
  }

  async originalFolderOfFolder(folder: ProjectFolder, project: Project): Promise<ProjectFolder> {
    const data = await this.requestHandler.get<IStoredProjectFolder>(
      `${this.entityBase}/original-folder-of-folder/${folder.getId()}`
    );

    return ProjectCommonFolder.fromIProjectFolder(this, project, data);
  }

  async getFiltersData(): Promise<any> {
    return await this.requestHandler.get<any>(`${this.entityDVMBase}/getFiltersData`);
  }

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.entityBase}/datagrid`);
  }

  async addChildTo(parent: ProjectFolder, folder: ProjectFolder): Promise<void> {
    return this.requestHandler.post(`${this.foldersEntityBase}/${folder.getId()}/move-to/${parent.getId()}`);
  }

  async createFolderChildOf(folder: ProjectFolder, childName: string): Promise<ProjectFolder> {
    const data = await this.requestHandler.post<IStoredProjectFolder, IBaseProjectFolder>(
      this.foldersEntityBase,
      {
        pca_nombre: childName,
        pca_padre: folder.getId(),
        pca_proid: folder.getProject().getId(),
        pca_tipo: ProjectFolderType.folder,
      }
    );

    return ProjectCommonFolder.fromIProjectFolder(this, folder.getProject(), data);
  }

  async changeNameOf(folder: ProjectFolder, value: string): Promise<unknown> {
    return this.requestHandler.patch<unknown, Partial<IBaseProjectFolder>>(
      `${this.foldersEntityBase}/${folder.getId()}`,
      { pca_nombre: value }
    );
  }

  getFakeDocumentFromError(error: string): SystemUploadedDocument {
    return this.documentsSystem.getFakeDocumentFromError(error);
  }

  private getParamsForSummary(from?: DateTime, to?: DateTime) {
    let params = '';

    if (from && to) {
      const _from = from.toFormat('yyyy-LL-dd');
      const _to = to.toFormat('yyyy-LL-dd');
      params = `?from=${_from}&to=${_to}`;
    }
    return params;
  }

  async getSummaryOf(
    project: IProjectDescriptor,
    from?: DateTime,
    to?: DateTime
  ): Promise<Required<IProjectSummary>> {
    let params = this.getParamsForSummary(from, to);
    const { activitiesByDay, documentsByDay, emailsMMIByDay, ...data } =
      await this.requestHandler.get<IProjectSummaryData>(
        `${this.entityBase}/summary/${project.getId()}${params}`
      );

    const sortedActivitiesByDay = activitiesByDay
      ? activitiesByDay.sort((a, b) => a.fecha.localeCompare(b.fecha))
      : [];
    const sortedDocumentsByDay = documentsByDay
      ? documentsByDay.sort((a, b) => a.fecha.localeCompare(b.fecha))
      : [];
    const sortedEmailsMMIByDay = emailsMMIByDay
      ? emailsMMIByDay.sort((a, b) => a.fecha.localeCompare(b.fecha))
      : [];

    return {
      ...data,
      activitiesByDay: sortedActivitiesByDay,
      documentsByDay: sortedDocumentsByDay,
      emailsMMIByDay: sortedEmailsMMIByDay,
      activitiesByWeek: this.mapQuantityByDayToWeek(sortedActivitiesByDay),
      documentsByWeek: this.mapQuantityByDayToWeek(sortedDocumentsByDay),
      emailsMMIByWeek: this.mapQuantityByDayToWeek(sortedEmailsMMIByDay),
    };
  }

  async getCollaboratorSummaryOf(
    project: IProjectDescriptor,
    from?: DateTime,
    to?: DateTime
  ): Promise<IProjectCollaboratorSummary[]> {
    let params = this.getParamsForSummary(from, to);
    const data = await this.requestHandler.get<IProjectCollaboratorSummary[]>(
      `${this.entityBase}/summary-collaborators/${project.getId()}${params}`
    );
    return data;
  }

  async getCompaniesSummaryOf(
    project: IProjectDescriptor,
    from?: DateTime,
    to?: DateTime
  ): Promise<IProjectCompanySummary[]> {
    let params = this.getParamsForSummary(from, to);
    const data = await this.requestHandler.get<IProjectCompanySummary[]>(
      `${this.entityBase}/summary-companies/${project.getId()}${params}`
    );
    return data;
  }

  private mapQuantityByDayToWeek(sortedByDate: IQuantityByDate[]) {
    const weeks: IQuantityByWeek[] = [];
    let start = 0;
    const offset = 7;
    while (start < sortedByDate.length) {
      const week = sortedByDate.slice(start, start + offset);
      weeks.push({
        start: week[0].fecha,
        end: week[week.length - 1].fecha,
        qty: week.reduce((acc, val) => acc + val.qty, 0),
      });
      start += offset;
    }
    return weeks;
  }

  /**
   * @TODO ver si aplica para los documentos
   * de actividades y liquidaciones también
   */
  deactivateProjectDocument(document: ProjectRelatedDocument) {
    return this.documentsSystem.deactivateProjectDocument(document);
  }

  restoreProjectDocumentFromTrash(document: ProjectRelatedDocument) {
    return this.documentsSystem.restoreFromTrash(document);
  }

  getDocumentsSystem(): DocumentsSystem {
    return this.documentsSystem;
  }

  getOrganizationNode(nodeId: string) {
    return this.organizationChartSystem.getNode(nodeId);
  }

  getOrganizationChartSystem() {
    return this.organizationChartSystem;
  }

  documentTypeFor(documentTypeId: string): LazyInitialization<SystemDocumentType> {
    return this.documentsSystem.documentTypeFor(documentTypeId);
  }

  collaboratorIdentifiedBy(id: string): LazyInitialization<Collaborator> {
    return this.collaboratorsSystem.getCollaboratorIdentifiedByLazy(id);
  }

  private mapProjectFolders(data: IStoredProjectFolder[], project: Project): ProjectFolder[] {
    return data.map((folder) => this.mapStoredProjectFolder(folder, project));
  }

  private mapStoredProjectFolder(folder: IStoredProjectFolder, project: Project): ProjectFolder {
    if (folder.pca_tipo === ProjectFolderType.activities) {
      return ProjectActivityFolder.fromIProjectFolder(this, project, folder);
    }
    if (folder.pca_tipo === ProjectFolderType.settlements) {
      return ProjectSettlementsFolder.fromIProjectFolder(this, project, folder);
    }
    if (folder.pca_tipo === ProjectFolderType.externalIntegration) {
      return ProjectExternalIntegrationFolder.fromIProjectFolder(this, project, folder);
    }
    if (folder.pca_tipo === ProjectFolderType.trash) {
      return ProjectTrashFolder.fromIProjectFolder(this, project, folder);
    }
    return ProjectCommonFolder.fromIProjectFolder(this, project, folder);
  }

  private async buildProjectRelatedDocument(
    doc: StoredDocumentRelatedToProject,
    project: Project
  ): Promise<ProjectRelatedDocument> {
    if (this.isProjectDocument(doc)) {
      const controlReference = doc.referenciaDocumentoObligatorio
        ? ((await this.mapIDocumentControlSaved(
            doc.referenciaDocumentoObligatorio,
            project
          )) as DocumentUploadControlConfirmed)
        : undefined;
      const systemDoc = this.documentsSystem.getSystemDocumentFromIUploadedDocument(doc, controlReference);
      return ProjectDocument.fromIStoredProjectDocument(this, systemDoc, project);
    }

    const controlReference = doc.documento?.referenciaDocumentoObligatorio
      ? ((await this.mapIDocumentControlSaved(
          doc.documento.referenciaDocumentoObligatorio,
          project
        )) as DocumentUploadControlConfirmed)
      : undefined;
    const systemDoc = doc.documento
      ? this.documentsSystem.getSystemDocumentFromIUploadedDocument(doc.documento, controlReference)
      : new SystemNotUploadedDocument();

    if (this.isActivityDocument(doc)) {
      return ActivityDocument.fromIStoredProjectActivityDocument(this, systemDoc, project, doc);
    }
    if (this.isSettlementItemDocument(doc)) {
      return SettlementDocument.fromIStoredProjectSettlementDocument(this, systemDoc, project, doc);
    }
    throw new Error('Invalid document');
  }

  private isProjectDocument(doc: StoredDocumentRelatedToProject): doc is IUploadedDocument {
    return 'doc_id' in doc;
  }

  private isActivityDocument(doc: StoredDocumentRelatedToProject): doc is IStoredProjectActivityDocument {
    return 'dxa_id' in doc;
  }

  private isSettlementItemDocument(
    doc: StoredDocumentRelatedToProject
  ): doc is IStoredProjectSettlementDocument {
    return 'dxli_id' in doc;
  }

  async getContext(): Promise<FormContextProject> {
    const [ret, availableNodes, nodes] = await Promise.all([
      this.requestHandler.get<IFormContextProjectCommon>(ProjectsSystem.formContextUrl),
      this.organizationChartSystem.getLastLevelNodes(),
      this.organizationChartSystem.getAllNodes(),
    ]);

    return {
      companyList: ret.common.empresas.map((emp) => Company.fromJson(emp)),
      organizationChartNodes: availableNodes,
      collaboratorList: ret.common.colaboradores.map((col) =>
        Collaborator.fromICollaborator(this.collaboratorsSystem, col, nodes)
      ),
      documentTypeList: ret.common.documentos_tipo.map((dtp) => SystemDocumentType.fromIDocumentType(dtp)),
    };
  }

  public getCollaboratorSystem() {
    return this.collaboratorsSystem;
  }
  public getCompanySystem() {
    return this.companySystem;
  }

  calculatePercentageAssignationHours(
    inicio: Date | string,
    fin: Date | string,
    colId: string,
    porcentajeAsignacion: number
  ) {
    return this.collaboratorTeamSystem.calcularAsignacionDeHoras(inicio, fin, colId, porcentajeAsignacion);
  }

  async getProjectTeamIdentifiedBy(id: string): Promise<ProjectTeam> {
    const data = await this.requestHandler.get<IProjectTeam>(`${this.EquipoDVMBase}/${id}`);
    return ProjectTeam.fromIProjectTeam(this.collaboratorsSystem, data);
  }

  async save(project: Project) {
    const saveData = project.toJson();
    const savedProjectResult = await this.requestHandler.post<ProjectJson, ProjectJson>(
      this.entityBase,
      saveData
    );
    return Project.fromJson(this, savedProjectResult);
  }

  async getListForDefenseFile(companyId: string, from: DateTime, to: DateTime) {
    let _formattedFrom = from.toFormat('yyyy-LL-dd');
    let _formattedTo = to.toFormat('yyyy-LL-dd');
    const params = `?skip=0&filter[0][name]=pro_inicio&filter[0][value]=${_formattedFrom}&filter[0][operator]=beforeOrOn&filter[1][name]=pro_fin&filter[1][value]=${_formattedTo}&filter[1][operator]=afterOrOn&filter[2][name]=pro_activo&filter[2][value]=1&filter[2][operator]=eq&filter[3][name]=emp_id&filter[3][value]=${companyId}&filter[3][operator]=eq&sort[0][name]=pro_codigo&sort[0][direction]=1&sort[0][id]=pro_codigo&sort[0][type]=string`;
    const data = await this.requestHandler.get<IProjectForDefenseFile[]>(
      `${this.entityBase}/datagrid${params}`
    );

    return data;
  }

  async getListForSettlement(company: Company, from: DateTime, to: DateTime) {
    let _formattedFrom = from.startOf('month').toFormat('yyyy-LL-dd');
    let _formattedTo = to.startOf('month').toFormat('yyyy-LL-dd');
    let params = `?skip=0&filter[0][name]=inicio&filter[0][value]=${_formattedFrom}&filter[0][operator]=afterOrOn&filter[1][name]=fin&filter[1][value]=${_formattedTo}&filter[1][operator]=beforeOrOn&filter[2][name]=pro_activo&filter[2][value]=1&filter[2][operator]=eq&filter[3][name]=emp_id&filter[3][value]=${company.getId()}&filter[3][operator]=eq&sort[0][name]=pro_codigo&sort[0][direction]=1&sort[0][id]=pro_codigo&sort[0][type]=string&sort[1][name]=periodo&sort[1][direction]=1&sort[1][id]=periodo&sort[1][type]=string&por_periodo=1&liquidacion_indirecta=1`;
    const data = await this.requestHandler.get<{ data: IProjectForDefenseFile[] }>(
      `${this.entityBase}/datagrid${params}`
    );

    return data.data;
  }

  private readonly controlUploadDocBase = '/control-carga-documentos';

  async getDocumentControlList(project: Project): Promise<DocumentUploadControlSaved[]> {
    const items = await this.requestHandler.get<IDocumentControlSaved[]>(
      `${this.controlUploadDocBase}/project/${project.getId()}`
    );

    const controls = items.map((item) => this.mapIDocumentControlSaved(item, project));

    return Promise.all(controls) as Promise<DocumentUploadControlSaved[]>;
  }

  async mapIDocumentControlSaved(
    item: IDocumentControlSaved,
    project: Project
  ): Promise<DocumentUploadControl> {
    const collaboratorId = item.ccdp_responsable_provisorio;
    let responsible = await this.collaboratorFromProjectOrSystem(project, collaboratorId);

    const control = DocumentUploadControlPending.from(
      await this.documentTypeFor(item.ccdp_doctid).value(),
      responsible,
      item.ccdp_concepto,
      this.dateParser.parseNoTime(item.ccdp_fecha_limite)
    );

    if (!item.ccdp_id) return control;
    const savedControl = DocumentUploadControlPendingSaved.from(item.ccdp_id, control);

    if (!item.ccdp_docid) return savedControl;

    return DocumentUploadControlConfirmed.from(
      savedControl,
      await this.collaboratorFromProjectOrSystem(project, item.ccdp_responsable!),
      this.dateParser.parseNoTime(item.ccdp_fecha_carga!),
      await this.getDocumentById(item.ccdp_docid!)
    );
  }

  getDocumentControlListForManagerBaseUrlForDatagrid(project: Project) {
    return this.requestHandler.requestUrl(
      `${this.controlUploadDocBase}/datagrid-all-of-project/${project.getId()}`
    );
  }

  async getDocumentControlIdentifiedBy(id: string, project: Project): Promise<DocumentUploadControlSaved> {
    let doc = await this.requestHandler.get<IDocumentControlSaved>(`${this.controlUploadDocBase}/${id}`);
    const controls = this.mapIDocumentControlSaved(doc, project);

    return Promise.resolve(controls) as Promise<DocumentUploadControlSaved>;
  }

  async getDocumentControlListForManager(project: Project): Promise<IDocumentControlForListManager[]> {
    const items = await this.requestHandler.get<IDocumentControlForListManager[]>(
      `${this.controlUploadDocBase}/datagrid-all-of-project/${project.getId()}`
    );
    return items;
  }

  async mapIDocumentControlForManagerList(
    item: IDocumentControlForList,
    project: Project
  ): Promise<IDocumentControlForListManager> {
    let responsible: Collaborator | null = null;
    if (item.ccdp_responsable) {
      responsible = await this.collaboratorFromProjectOrSystem(project, item.ccdp_responsable);
    }

    let responsible_prov = await this.collaboratorFromProjectOrSystem(
      project,
      item.ccdp_responsable_provisorio
    );

    return {
      id: item.ccdp_id,
      documentId: item.ccdp_docid,
      documentName: item.documento?.doc_nombre,
      documentType: item.documentoTipo.doct_nombre,
      collaborator: responsible ? responsible.fullName() : responsible_prov.fullName(),
      limitDate: item.ccdp_fecha_limite,
      uploadDate: item.ccdp_fecha_carga,
      concept: item.ccdp_concepto,
    };
  }

  async assignDocumentForDocumentControl(
    documentControlId: string,
    documentId: string
  ): Promise<IDocumentControl> {
    const data = await this.requestHandler.post<IDocumentControl, IAssignDocumentData>(
      `${this.controlUploadDocBase}/assign-document/${documentControlId}`,
      {
        documentId: documentId,
      }
    );

    return data;
  }

  async unassignDocumentForDocumentControl(documentControlId: string): Promise<IDocumentControl> {
    const data = await this.requestHandler.post<IDocumentControl, any>(
      `${this.controlUploadDocBase}/unassign-document/${documentControlId}`,
      {}
    );

    return data;
  }

  async deactivate(id: string): Promise<unknown> {
    return await this.requestHandler.delete(`${this.entityBase}/${id}`);
  }

  async reactivate(id: string): Promise<unknown> {
    return await this.requestHandler.put<any, any>(`${this.entityBase}/reactivar/${id}`, {});
  }

  async setFavorite(id: string): Promise<unknown> {
    return await this.requestHandler.put<any, any>(`${this.entityBase}/set-favorite/${id}`, {});
  }

  async unsetFavorite(id: string): Promise<unknown> {
    return await this.requestHandler.put<any, any>(`${this.entityBase}/unset-favorite/${id}`, {});
  }

  async getActivitiesToEditByPeriod(
    projectId: string,
    from: DateTime,
    to: DateTime
  ): Promise<ActivityJson[]> {
    let _formattedFrom = from.toFormat('yyyy-LL-dd');
    let _formattedTo = to.toFormat('yyyy-LL-dd');

    const qty = await this.requestHandler.get<ActivityJson[]>(
      `${this.entityBase}/activities-in-period/${projectId}/${_formattedFrom}/${_formattedTo}`
    );

    return qty;
  }

  async getQtyActivitiesToEditByPeriod(projectId: string, from: DateTime, to: DateTime): Promise<number> {
    let _formattedFrom = from.toFormat('yyyy-LL-dd');
    let _formattedTo = to.toFormat('yyyy-LL-dd');

    const qty = await this.requestHandler.get<number>(
      `${this.entityBase}/qty-activities-in-period/${projectId}/${_formattedFrom}/${_formattedTo}`
    );

    return qty;
  }

  async applyDisttributionInPeriod(distInfo: IEditDistributionInPeriod): Promise<unknown> {
    return await this.requestHandler.post<IEditDistributionInPeriod, any>(
      `${this.entityBase}/edit-distribution-in-period`,
      distInfo
    );
  }

  private async collaboratorFromProjectOrSystem(project: Project, collaboratorId: string) {
    const collaborators = await project.collaborators();
    let coll = collaborators.find((collaborator) => collaborator.isIdentifiedBy(collaboratorId));
    if (!coll) coll = await this.collaboratorIdentifiedBy(collaboratorId).value();
    return coll;
  }
}

export default ProjectsSystem;
