import Environment from '../lib/Environment';
import XhrRequestHandler from '../lib/XhrRequestHandler';
import CollaboratorSystem from './collaborators/CollaboratorSystem';
import DocumentsSystem from './documents/DocumentsSystem';
import DocumentsUploader, { IFilesUploadTracker } from './documents/DocumentsUploader';
import Project from './projects/Project';
import ProjectsSystem from './projects/ProjectsSystem';
import { SystemDateParser } from './SystemDateParser';
import { ActivitySystem } from './activity/ActivitySystem';
import ActivitySystemOptions from './activity/ActivitySystemOptions';
import CompanySystem from './company/CompanySystem';
import { NonWorkingDaysSystem } from './non_working_days/NonWorkingDaysSystem';
import { PanelSystem } from './panel/PanelSystem';
import { SettlementSystem } from './settlement/SettlementSystem';
import { RecoverPasswordSystem } from './recover_password/RecoverPasswordSystem';
import { DefenseFileSystem } from './defense_file/DefenseFileSystem';
import AuthenticationSystem from './AuthenticationSystem';
import CollaboratorTeamSystem from './collaborators/collaborator_team/CollaboratorTeamSystem';
import TaxOfficeSystem from './tax_office/TaxOfficeSystem';
import ProjectFolder from './projects/ProjectFolder';
import { Activity } from './activity/Activity';
import { Company } from './company/Company';
import ProjectInSettlementPeriod from './settlement/ProjectInSettlementPeriod';
import FileFolder from '../Components/FileDropzone/FileFolder';
import { DateTime } from 'luxon';
import { Collaborator } from './collaborators/Collaborator';
import { Settlement } from './settlement/Settlement';
import { SettlementProjectInPeriod } from './settlement/SettlementProjectInPeriod';
import { SettlementProjectInPeriodItem } from './settlement/SettlementProjectInPeriodItem';
import { ReportingSystem } from './reporting/ReportingSystem';
import ProjectDescriptor from './projects/ProjectDescriptor';
import { NotificationSystem } from './notifications/NotificationSystem';
import OrganizationChartSystem from './organization_chart/OrganizationChartSystem';
import XhrRequestCache from '../lib/XhrRequestCache';
import ConfigurationJson from './configuration/ConfigurationJson';
import SystemConfiguration from './configuration/SystemConfiguration';
import collaboratorLicenseSystem from './collaborators/collaborator_license/CollaboratorLicenseSystem';
import CollaboratorLicenseSystem from './collaborators/collaborator_license/CollaboratorLicenseSystem';
import { MailSystem } from './mail/MailSystem';
import { MailInspectorRulesTagSystem } from './mail/mail_inspector_rules_tag/MailInspectorRulesTagSystem';
import XhrErrorListener from '../lib/XhrErrorListener';
import { IPeriodDatesBlocked, PeriodDatesBlocked } from './period_dates_blocked/PeriodDatesBlocked';
import CostPerHourSystem from './cost_per_hour/CostPerHourSystem';
import { FieldValues } from 'react-hook-form';
import { IList_cnih } from '../Components/Collaborator/CollaboratorView/updateCollaborator/InformacionGeneral';
import { IEstructuraOrganizacional } from '../Components/Collaborator/CollaboratorView/updateCollaborator/interface';

class TimeTaxSupportSystemClient {
  private static readonly systemBase = '/system';
  private static readonly configBase = `${this.systemBase}/config`;
  private static readonly periodDatesBlockedBase = `/periodos-bloqueo-carga`;

  private projectsSystem: ProjectsSystem;
  private activitySystem: ActivitySystem;
  private recoverPasswordSystem: RecoverPasswordSystem;
  private authenticationSystem: AuthenticationSystem;
  private defenseFileSystem: DefenseFileSystem;
  private settlementSystem: SettlementSystem;
  private taxOfficeSystem: TaxOfficeSystem;
  private panelSystem: PanelSystem;
  private companySystem: CompanySystem;
  private reportingSystem: ReportingSystem;
  private notificationSystem: NotificationSystem;
  private organizationChartSystem: OrganizationChartSystem;
  private collaboratorLicenseSystem: collaboratorLicenseSystem;
  private mailSystem: MailSystem;
  private mailInspectorRulesTagSystem: MailInspectorRulesTagSystem;
  private costPerHourSystem: CostPerHourSystem;

  private constructor(
      private requestHandler: XhrRequestHandler,
      environment: Environment,
      dateParser: SystemDateParser,
      private documentsSystem: DocumentsSystem,
      private collaboratorsSystem: CollaboratorSystem
  ) {
    this.companySystem = new CompanySystem(requestHandler);
    this.organizationChartSystem = collaboratorsSystem.getOrganizationChartSystem();
    this.reportingSystem = new ReportingSystem(requestHandler, this.organizationChartSystem);

    this.projectsSystem = new ProjectsSystem(
        this.requestHandler,
        dateParser,
        this.documentsSystem,
        this.collaboratorsSystem,
        this.companySystem,
        new CollaboratorTeamSystem(requestHandler),
        this.organizationChartSystem
    );

    this.activitySystem = new ActivitySystem(
        requestHandler,
        dateParser,
        this.collaboratorsSystem,
        this.projectsSystem,
        new ActivitySystemOptions(requestHandler),
        this.companySystem,
        new NonWorkingDaysSystem(requestHandler),
        new CollaboratorLicenseSystem(requestHandler, this.collaboratorsSystem)
    );

    this.recoverPasswordSystem = new RecoverPasswordSystem(requestHandler);
    this.defenseFileSystem = new DefenseFileSystem(
        requestHandler,
        dateParser,
        collaboratorsSystem,
        this.companySystem
    );
    this.panelSystem = new PanelSystem(requestHandler);
    this.settlementSystem = new SettlementSystem(
        requestHandler,
        dateParser,
        this.companySystem,
        this.collaboratorsSystem,
        this.projectsSystem
    );

    this.authenticationSystem = new AuthenticationSystem(
        requestHandler,
        environment,
        dateParser,
        collaboratorsSystem
    );

    this.taxOfficeSystem = new TaxOfficeSystem(requestHandler);
    this.notificationSystem = new NotificationSystem(requestHandler, dateParser);
    this.mailSystem = new MailSystem(requestHandler);
    this.mailInspectorRulesTagSystem = new MailInspectorRulesTagSystem(requestHandler);
    this.costPerHourSystem = new CostPerHourSystem(requestHandler);
    this.collaboratorLicenseSystem = new CollaboratorLicenseSystem(requestHandler, collaboratorsSystem);
  }

  static start(environment: Environment) {
    // 24/05/2022 15:08
    const dateParser = SystemDateParser.defaultParser();
    const requestHandler = new XhrRequestHandler(
        environment,
        XhrRequestCache.with([ActivitySystem.commonContextUrl, ProjectsSystem.formContextUrl, this.configBase,"/loginDVM/menu-controller"])
    );
    const organizationChartSystem = new OrganizationChartSystem(requestHandler);

    const collaboratorSystem = new CollaboratorSystem(
        requestHandler,
        dateParser,
        organizationChartSystem,
        ActivitySystem.commonContextUrl
        // collaboratorLicenseSystem
    );
    const documentsSystem = new DocumentsSystem(
        requestHandler,
        DocumentsUploader.start(requestHandler),
        dateParser,
        collaboratorSystem
    );
    return new this(requestHandler, environment, dateParser, documentsSystem, collaboratorSystem);
  }

  unsubscribe(aListener: XhrErrorListener) {
    return this.requestHandler.unsubscribe(aListener);
  }

  subscribe(aListener: XhrErrorListener) {
    return this.requestHandler.subscribe(aListener);
  }

  downloadDefenseFile(defenseFileId: string) {
    return this.defenseFileSystem.downloadDefenseFileZipped(defenseFileId);
  }

  async configuration(): Promise<SystemConfiguration> {
    const conf = await this.requestHandler.get<ConfigurationJson>(TimeTaxSupportSystemClient.configBase);
    return SystemConfiguration.with(
        conf.conf_fecha_bloqueo_actividades
            ? this.getDateParser().parseNoTime(conf.conf_fecha_bloqueo_actividades)
            : null
    );
  }

  async periodsBlocked(): Promise<PeriodDatesBlocked[]> {
    const periods = await this.requestHandler.get<IPeriodDatesBlocked[]>(
        TimeTaxSupportSystemClient.periodDatesBlockedBase
    );
    return periods.map((p) => PeriodDatesBlocked.buildFrom(p));
  }

  async getAllPeriodsBlocked(): Promise<PeriodDatesBlocked[]> {
    let res = await this.requestHandler.get<IPeriodDatesBlocked[]>(
        `${TimeTaxSupportSystemClient.periodDatesBlockedBase}/all-periods`
    );
    return res.map((item) => {
      return PeriodDatesBlocked.buildFrom(item);
    });
  }

  async blockMonth(month: Date): Promise<unknown> {
    let _month = DateTime.fromJSDate(month).toFormat('yyyy-LL-dd');
    let res = await this.requestHandler.post<unknown>(
        `${TimeTaxSupportSystemClient.periodDatesBlockedBase}/block-month`,
        { month: _month }
    );
    return res;
  }

  async unblockMonth(month: Date): Promise<unknown> {
    let _month = DateTime.fromJSDate(month).toFormat('yyyy-LL-dd');
    let res = await this.requestHandler.put<unknown, { month: string }>(
        `${TimeTaxSupportSystemClient.periodDatesBlockedBase}/unblock-month`,
        { month: _month }
    );
    return res;
  }

  loggedInUser() {
    return this.authenticationSystem.loggedInUser();
  }

  signIn(email: string, password: string, token: string) {
    return this.authenticationSystem.signIn(email, password, token);
  }

  corporateSignIn() {
    return this.authenticationSystem.corporateSingIn();
  }

  signOut(returnTo: string) {
    return this.authenticationSystem.signOut(returnTo);
  }
//create colaboradores
  createUser(datos:FieldValues,id:string){
    return this.authenticationSystem.createUser(datos,id);
  }

  getselcts(nor_nivel?:string,nor_padre?:string){
    return this.authenticationSystem.getSelects(nor_nivel,nor_padre);
  }

  listCargo(){
    return this.authenticationSystem.lisCargo();
  }

  listCargoTypeComboBoxOptions(){
    return this.authenticationSystem.listCargoTypeComboBoxOptions();
  }

  UpdateCollaboratorUser(data){
   return this.authenticationSystem.updateColaboratorUser(data);
  }

  ProyectoenlaceList(email:string){
    return this.authenticationSystem.getListProyectoEnlace(email);
  }

  DelteProyectoEnlace(id:string){
    return this.authenticationSystem.DeleteUNProyectoEnlace(id);
  }

  modificarOneProyectoEnlace(datos:IList_cnih){
    return this.authenticationSystem.modificarOneProyectoEnlace(datos);
  }

  CrearOneProyectoEnlace(datos:IList_cnih,email:string){
    return this.authenticationSystem.CrearOneProyectoEnlace(datos,email)
  }

  CreateEstructuraOranizacional(datos:IEstructuraOrganizacional){
    return this.authenticationSystem.CreateEstructuraOranizacional(datos)
  }

  UpdateEstructuraOranizacional(datos:IEstructuraOrganizacional){
   return this.authenticationSystem.UpdateEstructuraOranizacional(datos);
  }

  ListEstructuraOrganizacional(id:string){
    return this.authenticationSystem.ListEstructuraOrganizacional(id);
  }

  DeleteEstructuraOrganizacional(id:string){
    return this.authenticationSystem.DeleteEstructuraOrganizacional(id);
  }

  menuService(){
    return this.authenticationSystem.menuService();
  }

  /**
   * @throws {XhrRequestError}
   */
  getProjects(): Promise<ProjectDescriptor[]> {
    return this.projectsSystem.list();
  }

  getAllProjectsByCollaborator(col: Collaborator): Promise<Project[]> {
    return this.projectsSystem.listOfCollaborator(col);
  }

  getAllProjectsByCollaboratorCargarFormulario(id: string): Promise<Project[]> {
    return this.projectsSystem.listOfCollaboratorProjects(id);
  }

  registerFileUploadTracker(tracker: IFilesUploadTracker) {
    return this.documentsSystem.registerFileUploadTracker(tracker);
  }

  async uploadFolderTo(fileFolder: FileFolder, target: ProjectFolder) {
    if (fileFolder.hasFiles()) {
      await this.uploadFilesToFolder(fileFolder.getFiles(), target);
    }

    return fileFolder.forEachChildAsync(async (child, childName) => {
      const nextTarget = await target.addChildNamed(childName);
      await this.uploadFolderTo(child, nextTarget);
    });
  }

  private async uploadFilesToFolder(files: File[], folder: ProjectFolder) {
    const documentTypeId = await this.documentsSystem.documentTypeOtherId();
    return this.documentsSystem.uploadFilesToProjectFolder(files, folder, documentTypeId);
  }

  async saveActivity(activity: Activity) {
    const savedActivity = await this.activitySystem.save(activity);
    if (activity.hasPendingDocuments()) {
      const pendingDocuments = activity.getDocumentActivityToSubmitList();
      this.documentsSystem.uploadFilesToActivity(pendingDocuments, savedActivity);
    }
    return savedActivity;
  }

  async checkDuplicates(activity: Activity, except: string = '') {
    const hasDuplicates = await this.activitySystem.checkDuplicates(activity, except);
    return hasDuplicates;
  }

  async generateSettlement(
      from: DateTime,
      to: DateTime,
      company: Company,
      projects: ProjectInSettlementPeriod[],
      additionalDocumentIds: string[],
      invoiceNumber?: string
  ): Promise<Settlement> {
    const { settlement, filesToUpload, documentReferences } = await this.settlementSystem.generateSettlement(
        from,
        to,
        company,
        projects,
        additionalDocumentIds,
        invoiceNumber
    );

    this.documentsSystem.uploadFilesToSettlement(filesToUpload, documentReferences, settlement.getId());

    return settlement;
  }

  async addSettlementItem(
      settlement: Settlement,
      settlementProject: SettlementProjectInPeriod,
      amount: number,
      description: string,
      file?: File
  ): Promise<SettlementProjectInPeriodItem> {
    const item = await this.settlementSystem.addSettlementItem(settlementProject, amount, description);

    if (file) {
      this.documentsSystem.uploadFilesToSettlementItem(file, item, settlement.getId());
    }
    return item;
  }

  /**
   * @throws {XhrRequestError}
   */
  documentTypeOtherId() {
    return this.documentsSystem.documentTypeOtherId();
  }

  getDateParser(): SystemDateParser {
    return new SystemDateParser('dd/MM/yyyy HH:mm');
  }

  getActivitySystem(): ActivitySystem {
    return this.activitySystem;
  }

  getDocumentSystem(): DocumentsSystem {
    return this.documentsSystem;
  }

  getCollaboratorSystem(): CollaboratorSystem {
    return this.collaboratorsSystem;
  }

  getCompanySystem(): CompanySystem {
    return this.activitySystem.getCompanySystem();
  }

  getProjectSystem(): ProjectsSystem {
    return this.projectsSystem;
  }

  getRecoverPasswordSystem(): RecoverPasswordSystem {
    return this.recoverPasswordSystem;
  }

  getDefenseFileSystem(): DefenseFileSystem {
    return this.defenseFileSystem;
  }

  getSettlementSystem(): SettlementSystem {
    return this.settlementSystem;
  }

  getTaxOfficeSystem(): TaxOfficeSystem {
    return this.taxOfficeSystem;
  }

  getPanelSystem(): PanelSystem {
    return this.panelSystem;
  }
  getReportingSystem(): ReportingSystem {
    return this.reportingSystem;
  }

  getNotificationSystem(): NotificationSystem {
    return this.notificationSystem;
  }

  getMailSystem(): MailSystem {
    return this.mailSystem;
  }

  getMailInspectorRulesTagSystem(): MailInspectorRulesTagSystem {
    return this.mailInspectorRulesTagSystem;
  }

  getOrganizationChartSystem(): OrganizationChartSystem {
    return this.organizationChartSystem;
  }

  getCollaboratorLicenseSystem(): CollaboratorLicenseSystem {
    return this.collaboratorLicenseSystem;
  }

  getAuthenticationSystem(): AuthenticationSystem {
    return this.authenticationSystem;
  }

  getCostPerHourSystem(): CostPerHourSystem {
    return this.costPerHourSystem;
  }

  getValidateExisteEmialDateBase () { 
    return this.collaboratorsSystem;
  }

}

export default TimeTaxSupportSystemClient;
