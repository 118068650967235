import { ComboBox, DatePicker, DefaultButton, Dropdown, IComboBoxOption, Icon, IDropdownOption, IDropdownStyles, IIconProps, Label, PrimaryButton, TextField } from "@fluentui/react";
import MessageCourier from "../../lib/MessageCourier";
import TimeTaxSupportSystemClient from "../../system";
import { User } from "../../system/User";
import { FormEvent, useEffect, useRef, useState } from "react";
import { convertHourToMin, convertMinToHour, FileUpload, formatDate, Iactivities, IconPapers, Ifileselect, IListFile } from "./FileUpload";
import { v4 as uuidv4 } from 'uuid';
import { getLocalizedStrings } from "../../lib/LocalizedStringsForDatePicker";
import { DateCustom } from "../../lib/DateCustom";
import { useTranslation } from "react-i18next";


interface ICompanyPanel {
    system: TimeTaxSupportSystemClient;
    courier: MessageCourier;
    changeBlockUI: (change: boolean) => void;
    user: User;
    useAllSector?: boolean;
  };
interface IAddRows {
    id: string;
    nombrePro: IComboBoxInput;
    nombreAct: string;
    horasCarg: string;
    file: string;
    valida: number;
    descripcionError: string;
}

interface IactError {
  actVali:number;
  actvErro:number;
}

interface ICollaborator {
  id: string;
  name: string;
  lastName: string;
  email: string;
  active: boolean;
}

interface IComboBoxInput extends IComboBoxOption{
  code?: string;
}

export interface IactivitiesBody {
  activities:Iactivities[];
  col_id: string;
  exercise_period: string;
  timezone: string;
}

export interface IactivitiesResponse {
  data:Iactivities[];
  error:string;
  type:string;
}

    const UploadIcon: IIconProps = { iconName: 'Upload' };
    const AddIcon: IIconProps = { iconName: 'add' }; 

function CargarPorFormulario(props: ICompanyPanel) {
  const headers = { idTransaccion:uuidv4(), msgTransaccion:"horas Total Collaborator" }
  const { t, i18n } = useTranslation();
    const [addRows,setAddRows] = useState<IAddRows[]>([]); 
    const [actError, setActError] = useState<IactError>({actVali:0,actvErro:0});
    const [desabled, setDesabled] = useState<boolean>(true);
    
    const [allCollaborators, setAllCollaborators] = useState<IComboBoxOption[]>([]);
    const [filteredCollaborators, setFilteredCollaborators] = useState<IComboBoxOption[]>([]);
    const [collaboratorValue, setCollaboratorValue] = useState<string>('');
    const [collaboratorId, setCollaboratorId] = useState<string>('');
    const [listFile, setListFile] = useState<IListFile[]>([]);
    
    const [projectsList, setProjectsList] = useState<IComboBoxOption[]>([]);
    const [filterProjects, setFilterProjects] = useState<IComboBoxOption[]>([]); 

    const [periodo,setPeriod] = useState<Date>(DateCustom.getFirstDayOfThisMonth())
    const [horasTotal, setHorasTotal] = useState<number>(0);
    const [desableAddRow, setDesableAddRow] = useState<boolean>(false);
    const previousValuesMap = useRef<Map<string, number>>(new Map());
    
    const handleSelectCollaborator =async (option: IComboBoxOption | undefined) => {
      cleanActiveProject()
      setCollaboratorValue(option?.text || "");
      if (option?.key !== undefined) {
        const data = String(option.key);
        setCollaboratorId(data);
        const exercise_period = formatDate(periodo);
        await fetchProjects(data)
        await  getHorasTal(data,exercise_period);
      }
    };

    const getIdfolder= async (id:string)=>{
      const respuesta = await props.system.getActivitySystem().IdFolder(id);
      return respuesta;
    }

    const handleFilterCollaborator = (text: string) => {
      setCollaboratorValue(text);
      const data = allCollaborators.filter(g => g.text.toLowerCase().includes(text.toLowerCase()));
      setFilteredCollaborators(data);
    };

    const formatCollaboratorData = (data: ICollaborator[]) => {
      const result: IComboBoxOption[] = data.map(col => ({
        key: col.id,
        text: `${col.name} ${col.lastName}`,
        active: col.active,
        email: col.email
      }));
      if (!result) return;
      setAllCollaborators(result);
      setFilteredCollaborators(result);
    };

    const fetchCollaborators = async () => {
      try {
        const data = await props.system.getActivitySystem().getCommonContext();
        formatCollaboratorData(data.colaboradores as ICollaborator[]);
      } catch (error) {
        console.error('Error fetching collaborators:', error);
      }
    };

    const fetchProjects = async (userData:string) => {
      try {
        if (!userData) return;
        const data = await props.system.getAllProjectsByCollaboratorCargarFormulario(userData);
        const transformsci =  data.map(v=>({ key:v.getId(),text:v.getName(), code:v.getCode() }));
        setProjectsList(transformsci);
        setFilterProjects(transformsci);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const validateRow = (row: IAddRows, id?: string) => {
      let descripcionError = "";
      const nombreActValido = /^[^\s]/.test(row.nombreAct) && !/\s{2,}/.test(row.nombreAct);
      const regex = /^(?:\d{1,3}:)?(\d{1,2}|undefined)?$/;
      const segundoRegex = /^:\d{1,2}$/;
      const valorMin =  convertHourToMin(row.horasCarg)
      const regexUndefine = /:undefined|undefined/;
    
      if ((!row.nombreAct && !row.horasCarg) || ( !row.nombrePro.text && !row.horasCarg)) {
        descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
      } else {
        if(!nombreActValido && (!row.nombrePro || !row.nombrePro.text)){
          descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
        }else if (!nombreActValido) {
          descripcionError = "El nombre de la actividad no puede ser vacío. ";
        } else if (row.nombreAct.length <= 3) {
          descripcionError = "El nombre es muy corto. ";
        }else if (!row.nombrePro.text) {
          descripcionError = "Nombre de proyecto vacío. ";
        }
        if (!row.horasCarg) {
          descripcionError = "Horas está vacío. ";
        } else if(valorMin === 0){
          
          if( !row.nombreAct || row.nombreAct === ""){
            descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
          }else if((valorMin === 0) && !row.nombrePro.text){
            descripcionError = "Los campos obligatorios (*) no pueden ser vacíos ";
          } else{
             descripcionError = "Hora no valida ";
          }
        }else if (!regex.test(row.horasCarg) && !segundoRegex.test(row.horasCarg) && regexUndefine.test(row.horasCarg)) {
          descripcionError = "Horas solo admite números. ";
        }

        
        if (id) {
          if (row.horasCarg === ":undefined" || row.horasCarg === "" || !row.horasCarg) {

            const prevValue = previousValuesMap.current.get(id) || 0;
            setHorasTotal(horasTotal + prevValue);
            previousValuesMap.current.delete(id);
          } else if (regex.test(row.horasCarg) || segundoRegex.test(row.horasCarg)) {
            const minIngresados = convertHourToMin(row.horasCarg);
            const prevValue = previousValuesMap.current.get(id) || 0;
            if ((horasTotal + prevValue - minIngresados) < 0) {
              descripcionError = "Ya no quedan horas disponibles.";
            }
            setHorasTotal(prevHorasTotal => prevHorasTotal + prevValue - minIngresados);
            previousValuesMap.current.set(id, minIngresados);
          }
        }
      }
    
      return { ...row, descripcionError, valida: descripcionError ? 0 : 1 };
    };

  
    const validateRowById = (rows: IAddRows[], id: string) => {

      const updatedRows = rows.map(row => row.id === id ? validateRow(row,row.id) : row);
      setAddRows(updatedRows);
      HandlerNumError(updatedRows);
    };
    
    const HandlerAddRows = () => {
      const newdata: IAddRows = {
        id: uuidv4(),
        nombrePro: { key: "", text: "" },
        nombreAct: "",
        horasCarg: "",
        file: "",
        valida: 2,
        descripcionError: "nueva fila"
      };
      setAddRows([...addRows, newdata]);
      setDesabled(true);
    };

    const HandlerDeleteRows = (id:string)=>{
      const result = addRows.filter(r=>r.id !== id);
      setAddRows(result);
      HandlerNumError(result);
      ///horas 
      const prevValue = previousValuesMap.current.get(id) || 0;
      setHorasTotal(horasTotal + prevValue);
      previousValuesMap.current.delete(id);
    }

    const HandlerNombreAct = (id: string, newValue: string | undefined) => {
      const valuess = newValue || "";
      const updatedRows = addRows.map(r => r.id === id ? { ...r, nombreAct: valuess } : r);
      validateRowById(updatedRows,id);
    };

    const HandlerContHoras = (id: string, newValue: string | undefined, type: 'hour' | 'minute') => {
      const updatedRows = addRows.map(row => {
        if (row.id === id) {
          const [currentHour, currentMinute] = row.horasCarg.split(':');
          const newHour = type === 'hour' ? newValue || '' : currentHour;
          const newMinute = type === 'minute' ? newValue || '' : currentMinute;
          return { ...row, horasCarg: `${newHour}:${newMinute}` };
        }
        return row;
      });
      validateRowById(updatedRows, id);
    };

    const onselectProject = (option: IComboBoxOption | undefined, id: string, inputText: string | undefined) => {
      const updatedRows = addRows.map(r => r.id === id ? { ...r, nombrePro: option || { key: "", text: inputText || "" } } : r);
      validateRowById(updatedRows, id);
    };
  
    const ProjectFilterChange = (text: string, id: string) => {
      const updatedRows = addRows.map(r => r.id === id ? { ...r, nombrePro: { ...r.nombrePro, text: text || "" } } : r);
      const data = projectsList.filter(g => g.text.toLowerCase().includes(text.toLowerCase()));
      setFilterProjects(data);
      validateRowById(updatedRows, id);
    };

    const HandlerPeriodo = (date:Date)=>{
      setPeriod(date);
      const exercise_period = formatDate(date);
      getHorasTal(collaboratorId,exercise_period);
      setHorasTotal(0);
      setAddRows([]);
      setDesabled(true);
      HandlerNumError([]);
    }

    const cleanActiveProject = ()=>{
     setHorasTotal(0);
     setAddRows([]);
     setProjectsList([]);
     setFilterProjects([]);
     setDesabled(true);
     HandlerNumError([]);
    }
    
    const handleFileSelect = async (file: File,project:IComboBoxOption,id:string) => {
       const idfolder:any = await  getIdfolder(String(project?.key));
      const idFile =await  props.system.documentTypeOtherId();
      if(idFile && idfolder){
        const data:IListFile ={ 
          path:file,
          id,
          file:{
        cmd_proid: project.key,
        cmd_cant_archivos: 1,
        cmd_cant_bytes_total: file.size,
        cmd_tipo: "proyecto",
        cargaDocumentosItems: [
          {
            cmdi_cant_bytes: file.size,
            cmdi_doc_uuid: "0",
            cmdi_nombre_archivo: file.name,
            cmdi_content_type: file.type,
            doctId: idFile,
            tipo: "proyecto"
          }
        ],
        folderId: String(idfolder?.pca_id)
        }
      }

      const updatedListFile = listFile.map(item => 
        item.id === data.id ? data : item
      );
      
      if (!listFile.some(item => item.id === data.id)) {
        updatedListFile.push(data);
      }
      
      setListFile(updatedListFile);
      }
    };

    const saveFile = async () => {
      for (const item of listFile) {
        try {
           await props.system.getActivitySystem().UploadfileForm(item.file, item.path);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    };

    const HandlerNumError = (validados: IAddRows[])=>{
        const actVali = validados.filter(g=>g.valida === 1);
        const actvErro = validados.filter(g=>g.valida === 0);
        const actvEarring = validados.filter(g=>g.valida === 2);
        setActError({actVali:actVali.length,actvErro:actvErro.length});
       if((actvErro.length === 0) && (validados.length !== 0) && (actvEarring.length === 0)){
         setDesabled(false);
       }else{
        setDesabled(true);
       } 
    };

    const onsubmit = async (ev: FormEvent<HTMLFormElement>) => {
     ev.preventDefault();
        if(addRows){
          const tranForm = addRows.map(row =>{
              const numer = convertHourToMin(row.horasCarg as any);
              return {
                impai_nombre: row.nombreAct,
                impai_cantidad_horas : convertMinToHour(numer),
                impai_codigo_proyecto: row.nombrePro.code,
                impai_proid: row.nombrePro.key,
                impai_con_error: false,
                impai_descripcion_error: row.descripcionError,
                impai_descripcion: ""
              }
          });

          const body:IactivitiesBody = {
            activities:tranForm,
            col_id: collaboratorId, 
            exercise_period:DateCustom.formatDateYYYYMM(periodo), 
            timezone: "America/Lima" 
          };
        const numProjetct = addRows.length;
        const result = await props.system.getActivitySystem().saveActivity(headers,body); 
        await saveFile();
        props.changeBlockUI(true);
            if(result.error.length){
              props.courier.messageError(t('Hubo un error inesperado. Por favor, intenta nuevamente más tarde'));
            }else{
              cleanActiveProject();
              props.courier.messageSuccess(t(`Se cargaron ${numProjetct} actividades de manera exitosa`));
            } 
        }
      props.changeBlockUI(false);
    };

    const getHorasTal = async (col_id:string,exercise_period:string)=>{
      setDesableAddRow(true)
      const body = { col_id,  exercise_period}
      const result = await props.system.getActivitySystem().saveActivityResult(headers,body);
      const data = result as any
      if(data.data){
       setHorasTotal(data?.data?.pending_minutes);
       setDesableAddRow(false)
      }
    }

      useEffect(()=>{
        fetchCollaborators()
      },[]);
    
      const minutoOptions: IDropdownOption[] = [
        { key: '0', text: '00' },
        { key: '15', text: '15' },
        { key: '30', text: '30' },
        { key: '45', text: '45' },
      ];
      const customStyles: Partial<IDropdownStyles> = {
        caretDown: {
          display: 'none', 
        },
      };
      
    return (
      <form className="ms-Grid" dir="ltr" style={{minHeight:"100%",fontFamily: 'Segoe UI, "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif'}} onSubmit={onsubmit}>
          <div className="ms-Grid-row ms-bgColor-gray20" style={{ display: "flex", alignItems: "center", padding: "20px" }}>
            <Icon iconName="PreviewLink" style={{ fontSize: "25px", color: "#106ebe", marginRight: "10px" }} />
            Carga por formulario
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg7">
                <ComboBox label="Colaborador" className="ms-Grid-col ms-sm4 ms-md6 ms-lg4" options={filteredCollaborators} 
                  allowFreeform text={collaboratorValue} onChange={(_,option)=>handleSelectCollaborator(option)}
                  onInputValueChange={(text)=>handleFilterCollaborator(text || "")} selectedKey={"00000006454"}
                />
                <DatePicker label="Periodo" className="ms-Grid-col ms-sm6 ms-md4 ms-lg4"  strings={getLocalizedStrings()}
                formatDate={DateCustom.formatDateForDatePicker_MMYYYY} calendarProps={{ isDayPickerVisible: false }}
                value={periodo}  onSelectDate={(e) => {
                  if (e) {
                    let _date = new Date(e.getFullYear(), e.getMonth(), 1);
                    HandlerPeriodo(_date);
                  }
                }}
                />
                <div  className="ms-Grid-col ms-sm4" >
                  <Label >Horas pendientes</Label>
                  <DefaultButton style={{color:horasTotal > -1 ? "black":"red",border:"none"}} >{convertMinToHour(horasTotal)}</DefaultButton>
                </div>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1" style={{ display: "flex", justifyContent: "center" }}>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4" style={{ display: "flex", justifyContent: "flex-end", gap: "20px", marginTop: "20px" }}>
                <PrimaryButton className="ms-Grid-col ms-sm12 ms-md6 ms-lg3" text="Cargar" 
                iconProps={UploadIcon} type="submit" disabled={desabled} />
              </div>
            </div>
          </div>
          <div  className="ms-Grid-row"  style={{padding:"30px 0px 10px 30px"}}>
            <h4  className="ms-Grid-col ms-sm11"  >Tienes {actError.actVali} actividades válidas y {actError.actvErro} actividades con errores</h4 >
          </div>
          <div className="ms-Grid-row" style={{padding:"0px 30px"}}>
            <div className="ms-Grid-col ms-sm12">
              <PrimaryButton disabled={projectsList.length === 0? true :desableAddRow?true:false} text="Agregar fila" style={{width:"150px"}} iconProps={AddIcon} onClick={()=>HandlerAddRows()} />
            </div>
            <div className="ms-Grid-col ms-sm12">
              <div style={{padding:"20px 20px"}}><Icon iconName="Info"/>Los documentos serán cargados al proyecto</div>    
            </div>
          </div>
          <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "700px",minHeight:"150px" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span className="ms-Grid-col ms-sm2 ms-lg2">Nombre del proyecto (*)</span>
                        <span className="ms-Grid-col ms-sm2 ms-lg2">Nombre de la actividad (*)</span>
                        <span className="ms-Grid-col ms-sm2 ms-lg2">Horas a cargar (*)</span>
                        <span className="ms-Grid-col ms-sm1 ms-lg1" style={{display:"flex",justifyContent:"center"}}>Valida</span>
                        <span className="ms-Grid-col ms-sm2 ms-lg2">Descripción del error</span>
                        <span className="ms-Grid-col ms-sm2 ms-lg2">Adjunto</span>
                        <span className="ms-Grid-col ms-sm1 ms-lg1" style={{display:"flex",justifyContent:"center"}}>Eliminar</span>
                    </div>
                    <hr />
                    <div >
                    {addRows.map(row => (
                        <div key={row.id}>
                            <div  style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <ComboBox className="ms-Grid-col ms-sm2" placeholder="Indique el proyecto" options={filterProjects} 
                                text={row.nombrePro.text} allowFreeform onChange={(_,option)=>onselectProject(option, row.id, '') }
                                onInputValueChange={(text) => ProjectFilterChange(text || "",row.id)}
                                />  
                                <TextField className="ms-Grid-col ms-sm2" placeholder="Escriba el nombre de la actividad"
                                value={row.nombreAct} onChange={(e, newValue) =>HandlerNombreAct(row.id, newValue)} 
                                />
                                <div className="ms-Grid-col ms-sm2" >
                                    <div className="ms-Grid-col ms-sm12"  >
                                    <TextField 
                                      placeholder="hh" 
                                      className="absolute horas"
                                      value={row.horasCarg.split(':')[0]} 
                                      onChange={(e, newValue) => HandlerContHoras(row.id, newValue, 'hour')}
                                    />
                                     <div className="absolute dosPuntos" style={{marginTop:"-32px"}} >:</div>
                                    <Dropdown
                                    className="absolute minutos"
                                    styles={customStyles}
                                      options={minutoOptions}
                                      placeholder={'mm'}
                                      selectedKey={row.horasCarg.split(':')[1]} 
                                      onChange={(event, option) => {
                                        if (option) HandlerContHoras(row.id, String(option.key), 'minute');
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="ms-Grid-col ms-sm1" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    {row.valida === 2 ? 
                                    <IconPapers key={"004"} nameIcon="Warning12" color="yellow" size="25px" thickness={0}/>:row.valida === 0 ?
                                    <IconPapers key={"003"} nameIcon="ChromeClose" color="red" size="25px" thickness={9}/>:
                                    <IconPapers key={"002"} nameIcon="Accept" color="green" size="25px" thickness={9}/>} 
                                </div>
                                <div className="ms-Grid-col ms-sm2">{row.descripcionError } </div>
                                <div className="ms-Grid-col ms-sm2">
                                  <FileUpload disabled={row?.nombrePro?.key? false:true} onFileSelect={(file)=>handleFileSelect(file,row.nombrePro,row.id)} />
                                </div>
                                <div className="ms-Grid-col ms-sm1" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <IconPapers key={"001"} color={"red"} nameIcon={"Delete"} size={"25px"} thickness={9} onClick={()=>HandlerDeleteRows(row.id)}/>
                                </div> 
                            </div>
                            <hr />
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </form>
      );
}


export default CargarPorFormulario